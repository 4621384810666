<template>
  <div class="mod-car">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="keyword">
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          @change="departmentSelectChange"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          placeholder="按班组筛选"
          @visible-change="teamSelect"
          @change="teamSelectChange"
        >
          <el-option
            v-for="item in teamOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          placeholder="按员工筛选"
          @visible-change="employeeSelect"
        >
          <el-option
            v-for="item in employeeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button type="info" @click="openBatchImportPopUp"
          >批量导入</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="所属部门"
      >
      </el-table-column>
      <el-table-column
        prop="teamName"
        header-align="center"
        align="center"
        label="所属班组"
      >
      </el-table-column>
      <el-table-column
        prop="employeeNameArr"
        header-align="center"
        align="center"
        label="所属员工"
      >
      </el-table-column>
      <el-table-column
        prop="number"
        header-align="center"
        align="center"
        label="车牌号"
      >
      </el-table-column>
      <el-table-column
        prop="nylx"
        header-align="center"
        align="center"
        label="能源类型"
      >
      </el-table-column>
      <el-table-column
        prop="yxlx"
        header-align="center"
        min-width="130"
        align="center"
        label="运行路线"
      >
      </el-table-column>
      <el-table-column
        prop="gzsj"
        header-align="center"
        align="center"
        label="购置时间"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.gzsj ? scope.row.gzsj.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="tcrq"
        header-align="center"
        align="center"
        label="退出日期"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.tcrq ? scope.row.tcrq.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sccj"
        header-align="center"
        align="center"
        min-width="130"
        label="生产厂家"
      >
      </el-table-column>
      <el-table-column
        prop="xh"
        header-align="center"
        align="center"
        label="型号"
      >
      </el-table-column>
      <el-table-column
        prop="zws"
        header-align="center"
        align="center"
        label="座位数"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="dlyszPic"
        header-align="center"
        align="center"
        label="道路运输证"
      >
        <template slot-scope="scope">
          <div>
            <el-image
              style="height: 50px"
              fit="contain"
              :src="$http.common.pic + scope.row.dlyszPic"
              :preview-src-list="[$http.common.pic + scope.row.dlyszPic]"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="xszPic"
        header-align="center"
        align="center"
        label="行驶证"
      >
        <template slot-scope="scope">
          <div>
            <el-image
              style="height: 50px"
              fit="contain"
              :src="$http.common.pic + scope.row.xszPic"
              :preview-src-list="[$http.common.pic + scope.row.xszPic]"
            ></el-image>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">车辆加入审批中</span>
          <span v-if="scope.row.status == 1">在网</span>
          <span v-if="scope.row.status == 2">已拒绝加入</span>
          <span v-if="scope.row.status == 3">修改车辆信息审批中</span>
          <span v-if="scope.row.status == 4">退网审批中</span>
          <span v-if="scope.row.status == 5">已退网</span>
          <span v-if="scope.row.status == 6">重新入网审批中</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="280"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0 || scope.row.status == 6"
            type="primary"
            size="mini"
            plain
            @click="approvalHandler(scope.row.id)"
            >入网审批</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            type="primary"
            size="mini"
            plain
            @click="reApprovalHandler(scope.row.id)"
            >重新入网审批</el-button
          >
          <el-button
            v-if="scope.row.status == 3"
            type="primary"
            size="mini"
            plain
            @click="updateApprovalHandler(scope.row)"
            >修改审批</el-button
          >
          <el-button
            v-if="scope.row.status == 4"
            type="primary"
            size="mini"
            plain
            @click="tuiwangApprovalHandler(scope.row.id)"
            >退网审批</el-button
          >
          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.status == 1"
            type="danger"
            size="mini"
            @click="approvalQuit(scope.row.id)"
            >退网</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 自定义组件 -->
    <template>
      <Examine
        ref="ExamingDom"
        v-if="examing_visible"
        @refreshDataList="getDataList"
      />

      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
      ></add-or-update>

      <!-- 批量新增组件 -->
      <batch-import
        ref="BImportDom"
        v-if="batchImport_visible"
        @refreshDataList="getDataList"
      ></batch-import>
    </template>

    <el-dialog title="提示" :visible.sync="quitDialogVisible" width="30%">
      <el-date-picker
        v-model="quitDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="请选择退网日期"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteCarHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import AddOrUpdate from "./car-add-or-update";
import Examine from "./examine.vue";
import BatchImport from "./component/batch-import.vue";

export default {
  components: {
    AddOrUpdate,
    Examine,
    BatchImport
  },
  data() {
    return {
      batchImport_visible: false,
      examing_visible: false,
      quitDialogVisible: false,
      cid: "",
      quitDate: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      departmentList: [], // 部门列表
      teamList: [], // 班组列表

      dataForm: {
        keyword: "",
        did: "",
        tid: "",
        eid: "",
      },

      departmentOptions: [], //部门列表选项
      teamOptions: [], //部门列表选项
      employeeOptions: [], //员工列表选项
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    approvalQuit(cid) {
      this.quitDialogVisible = true;
      this.cid = cid;
    },
    // 车辆退网
    deleteCarHandler() {
      this.$http.car
        .approvalQuit({ cid: this.cid, quitDate: this.quitDate })
        .then((res) => {
          if (res && res.code == 0) {
            this.$message.success(res.msg);
            this.getDataList();
            this.quitDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },
    // 部门选择(选择发生变化)
    departmentSelectChange(e) {
      this.dataForm.tid = "";
      this.teamOptions = [];
      this.dataForm.eid = "";
      this.employeeOptions = [];
    },
    // 班组选择(打开下拉框)
    teamSelect(e) {
      if (e) {
        this.$http.team
          .list({ did: this.dataForm.did, limit: 99999, page: 1 })
          .then((res) => {
            this.teamOptions = res?.data?.list;
          });
      }
    },
    // 班组选择(选择发生变化)
    teamSelectChange(e) {
      this.dataForm.eid = "";
      this.employeeOptions = [];
    },
    // 员工选择(打开下拉框)
    employeeSelect(e) {
      if (e) {
        this.$http.employee
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.employeeOptions = res?.data?.list;
          });
      }
    },

    // 退网审批
    tuiwangApprovalHandler(cid) {
      this.$confirm("是否同意该车辆退网", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意退网
          this.$http.car.quit({ cid: cid, flag: 0 }).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝退网
            this.$http.car.quit({ cid: cid, flag: 1 }).then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
    },
    // 信息修改审批
    updateApprovalHandler(row) {
      this.examing_visible = true;
      this.$nextTick(() => {
        this.$refs["ExamingDom"].init({
          id: row?.id || null,
          did: row?.did || null,
          tid: row?.tid || null,
        });
      });

      // this.examing_visible = true;
      // this.$nextTick(() => {
      //   this.$refs["ExamingDom"].init(cid);
      // });
      return;
      this.$confirm("是否同意该车辆信息修改", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意修改
          this.$http.car.approvalUpdate({ cid: cid, flag: 0 }).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝修改
            this.$http.car.approvalUpdate({ cid: cid, flag: 1 }).then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
    },
    // 审批新增车辆
    approvalHandler(cid) {
      this.$confirm("是否同意该车辆加入", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意入网
          this.$http.car.approvalAdd({ cid: cid, flag: 0 }).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝入网
            this.$http.car.approvalAdd({ cid: cid, flag: 1 }).then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
    },
    // 重新入网审批
    reApprovalHandler(cid) {
      this.$confirm("是否同意该车辆加入", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 同意入网
          this.$http.car.restartEnter({ cid: cid }).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.car.list(this.dataForm).then((data) => {
        if (data?.data?.list || false) {
          data.data.list.forEach((ele) => {
            let nameArr = [];
            ele.employeeList.forEach((emp) => {
              nameArr.push(emp.name);
            });
            ele["employeeNameArr"] = nameArr.join("_");
          });
        }

        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }

        this.dataListLoading = false;
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init({
          id: row?.id || null,
          did: row?.did || null,
          tid: row?.tid || null,
        });
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.car
          .delete({
            id,
          })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
      });
    },
    // 打开批量新增弹窗
    openBatchImportPopUp() {
      this.batchImport_visible = true;
      this.$nextTick(() => {
        this.$refs["BImportDom"].init();
      });
    },
  },
};
</script>
  